.name,
.email,
.phone,
.password,
.address {
    display: flex;
    flex-direction: column;
    gap: 4px;
   
}

.verify-phone,.proceed {
    margin-top: 12px;
    width: 100px;
    height: 40px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    border: none;
    border-radius: 8px;
    background-color: rgb(0, 163, 196);
}
.otp-input{
    display:flex;
    gap:10px;
   
    margin-left:auto;
    margin-right:auto;
}
.otp-inp{width:50px;
    border-radius:10px;
    border: 1px solid #666;
    background-color: transparent;
    font-size: 18px;
    color:rgb(0, 0, 0);
    outline:white;
    text-align: center;
    
        height:70px;}