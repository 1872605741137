.terms-section{
    width:calc(100vw - 150px);
    height:auto;
    padding:75px;
    
}
.header-terms{
    text-align: center;
    font-size:40px;
    color:#009FFD
}
.partition{
    width:90%;
    height:1px;
    background-color: rgb(182, 182, 182);
    margin-left:5%;
    margin-top:20px;
}
.t-data{
    margin-top:20px;
    display:flex;
    flex-direction: column;
    gap:24px;
}
.heading{
    font-weight: bold;
    font-size: 24px;
}
.sub-head{
    font-weight:550;
    margin-top:10px;
}
.desc{
    margin-top:20px;
}