.Modal {
  position: absolute;
  border-radius: 16px;
  background: #fff;
  outline: none;
  height: fit-content;
  width: fit-content;
  margin: 1rem;
  max-width: calc(1334px - 30px);

  @media screen and (max-width: 900px) {
    max-width: calc(100vw - 2rem);
    margin: 0rem;
    transform: scale(0.9);
  }

  @media screen and (max-width: 400px) {
    max-width: calc(100vw - 2rem);
  }
}

.Overlay {
  position: fixed;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
  justify-content: center;
}

.Overlay_zIndex {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
  justify-content: center;
}


